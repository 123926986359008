import React from 'react';
import Message from '../message/message';
import axios from 'axios';
import { Link } from '@mui/joy';

export type GitValidationErrorMessageProps = {
  error: unknown;
  retry?: () => void;
};

const GitValidationErrorMessage = ({
  error,
  retry,
}: GitValidationErrorMessageProps) => {
  const { isValidationError, apiErrorMessage, prompt } = React.useMemo(() => {
    if (axios.isAxiosError(error)) {
      const errorCode = error.response?.data?.error?.code;
      return {
        isValidationError:
          !!errorCode &&
          ['ERR_GITHUB_NOREPO', 'ERR_GITHUB_PAT', 'ERR_GITHUB_PERMS'].includes(
            errorCode,
          ),
        apiErrorMessage:
          error.response?.data?.error?.message || 'An Unknown Error Occurred',
        prompt:
          errorCode === 'ERR_GITHUB_NOREPO'
            ? 'Please ensure the Repository Name and Namespace are correct'
            : errorCode === 'ERR_GITHUB_PAT'
              ? 'Please ensure that the Personal Access Token is valid'
              : errorCode === 'ERR_GITHUB_PERMS'
                ? 'Please ensure that the Personal Access Token has the necessary scopes'
                : 'Please try again',
      };
    } else {
      return {
        isValidationError: false,
        apiErrorMessage: 'An Unknown Error Occurred',
      };
    }
  }, [error]);

  return (
    <Message
      variant='danger'
      title={
        isValidationError
          ? 'Invalid GitHub Repository Configuration'
          : 'Something went wrong'
      }
      style={{ fontSize: '16px' }}
    >
      {isValidationError ? (
        <>
          <div>{apiErrorMessage}</div>
          <div>{prompt}</div>
        </>
      ) : (
        <>
            <div>An Unknown Error Occurred</div>
            {retry && <Link component='a' onClick={() => retry()} color='danger'>Click here to try again</Link>}
        </>
      )}
    </Message>
  );
};

export default GitValidationErrorMessage;
