import React from 'react';
import Heading from '../../components/heading/heading';
import Card from '../../components/card/card';
import {
  FormControl,
  Input,
  FormHelperText,
  Autocomplete,
  AutocompleteOption,
  ListItemDecorator,
  ListItemContent,
  useTheme,
  Skeleton,
  Button,
  Select,
  Option,
  Tooltip,
  Typography,
} from '@mui/joy';
import { useMutation, useQuery } from 'react-query';
import { User as Auth0User } from '@auth0/auth0-react';
import miscService from '../../service/misc';
import LoadingMessage from '../../components/loading-message/loading-message';
import { Controller, useForm } from 'react-hook-form';
import { LanguageData } from '../../types/misc';
import CountryFlag from '../../components/country-flag/country-flag';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faTrashCan,
  faCrown,
  faCheck,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import userService from '../../service/user';
import { ProjectRole, User } from '../../types/user';
import authUtil from '../../util/auth';
import ProfileName from '../../components/profile-name/profile-name';
import { css } from 'aphrodite';
import commonStyles from '../../styles/common.styles';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../types/navigation';
import { ProjectCreateInput } from '../../types/project';
import projectService from '../../service/project';
import useAuth from '../../util/auth-hook';
import FormLabelWithIcon from '../../components/form-label-with-icon/form-label-with-icon';
import { GitRepoValidationInput } from '../../types/git';
import gitService from '../../service/git';
import GitValidationErrorMessage from '../../components/git-validation-error-message/git-validation-error-message';

type CreateProjectFormValues = {
  projectName: string;
  defaultLanguage: string;
  languages: LanguageData[];
  repoOwner: string;
  repoName: string;
  pat: string;
  emailAddress?: string;
};

type ProjectUserDetails = {
  user: User;
  role?: ProjectRole;
};

const CreateProject = () => {
  const { getAccessTokenSilently, user } = useAuth();

  const joyUiTheme = useTheme();

  const navigate = useNavigate();

  const [projectUsers, setProjectUsers] = React.useState<ProjectUserDetails[]>([
    {
      user: authUtil.getCurrentUserObject(user as Auth0User),
      role: ProjectRole.Admin,
    },
  ]);

  // the searchForUserByEmail endpoint will return a 404 if the user cannot be found
  // We don't want a general error message shown in this case
  // therefore this state will track whether the error returned is a 404, and so a different error should be shown
  const [isNewUserNotFoundError, setIsNewUserNotFoundError] =
    React.useState(false);

  // state to keep track of whether a user specified has already been added
  const [newUserAlreadyAdded, setNewUserAlreadyAdded] = React.useState(false);

  const addUserToProjectList = (user: User) => {
    setProjectUsers((existingUsers) => [
      ...existingUsers,
      { user, role: undefined },
    ]);
  };

  const removeUserFromProjectList = (userToRemove: User) => {
    setProjectUsers(
      projectUsers.filter((addedUser) => addedUser.user.id !== userToRemove.id),
    );
  };

  // iterates over the projectUsers state array
  // - if the user's id matches that to update, update the object with the new role
  // - else, leave the object as it is
  const changeUserRole = (userId: string, role: ProjectRole) => {
    setProjectUsers((existingUsers) =>
      existingUsers.map((existingUser) =>
        existingUser.user.id === userId
          ? { ...existingUser, role }
          : existingUser,
      ),
    );
  };

  const {
    data: languages,
    isFetching: isLanguagesFetching,
    isError: isLanguagesError,
    error: languagesError,
    refetch: refetchLanguages,
  } = useQuery(
    'languages',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getLanguages(accessToken);
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: projectRoles,
    isFetching: isProjectRolesFetching,
    isError: isProjectRolesError,
    error: projectRolesError,
    refetch: refetchProjectRoles,
  } = useQuery(
    'roles-assignable',
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await miscService.getRoles(accessToken, true);
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  // useMutation is used here rather than useQuery as caching not required, and allows for onSuccess/onError callbacks and a specific mutate function
  const {
    isLoading: isNewUserLoading,
    isError: isNewUserError,
    error: newUserError,
    mutate: searchForUser,
    reset: resetNewUser,
  } = useMutation(
    async (email: string) => {
      const accessToken = await getAccessTokenSilently();
      return await userService.searchForUserByEmail(accessToken, email);
    },
    {
      onError: (error) => {
        if (axios.isAxiosError(error) && error.status === 404) {
          resetNewUser();
          setIsNewUserNotFoundError(true);
        }
      },
      onSuccess: (user) => {
        for (const existingUser of projectUsers) {
          if (user.id === existingUser.user.id) {
            resetNewUser();
            setNewUserAlreadyAdded(true);
            return;
          }
        }
        addUserToProjectList(user);
        setValue('emailAddress', undefined);
      },
    },
  );

  const {
    isLoading: isProjectCreationLoading,
    isError: isProjectCreationError,
    error: projectCreationError,
    mutate: submitProject,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      const { projectName, languages, repoName, repoOwner, pat } = getValues();

      const projectInput: ProjectCreateInput = {
        name: projectName,
        defaultLang,
        langs: languages.map((langData) => langData.code),
        repoName,
        repoOwner,
        pat,
        users: projectUsers.map((projectUser) => ({
          userId: projectUser.user.id,
          role: projectUser.role as ProjectRole,
        })),
      };

      return await projectService.createProject(accessToken, projectInput);
    },
    {
      onSuccess: (data) => {
        navigate(`${Page.ProjectRoute}/${data.id}`);
      },
    },
  );

  const {
    isLoading: isValidateGitRepoLoading,
    isSuccess: isValidateGitRepoSuccess,
    isError: isValidateGitRepoError,
    error: validateGitRepoError,
    mutate: validateGitRepo,
    reset: resetValidateGitRepo,
  } = useMutation(async () => {
    const accessToken = await getAccessTokenSilently();

    const { repoName, repoOwner, pat } = getValues();

    const input: GitRepoValidationInput = {
      repoName,
      repoOwner,
      pat,
    };

    return await gitService.validateRepo(accessToken, input);
  });

  const validationSchema = Yup.object().shape({
    projectName: Yup.string()
      .required('Project Name is required')
      .max(255, 'Project Name must be no longer than 255 characters'),
    defaultLanguage: Yup.string().required('Default Language is required'),
    languages: Yup.array()
      .min(1, 'A project must support at least 1 other language')
      .required('Supported languages is required'),
    repoOwner: Yup.string()
      .required('Repository Namespace is required')
      .max(40, 'Repository Namespace must be no longer than 40 characters'),
    repoName: Yup.string()
      .required('Repository Name is required')
      .max(100, 'Repository Name must be no longer than 100 characters'),
    pat: Yup.string()
      .required('GitHub Personal Access Token is required')
      .max(
        255,
        'GitHub Personal Access Token must be no longer than 255 characters',
      ),
    emailAddress: Yup.string().email('Must be a valid email address'),
  });

  const {
    register,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<CreateProjectFormValues>({
    defaultValues: {
      projectName: undefined,
      defaultLanguage: undefined,
      languages: [],
      repoOwner: undefined,
      repoName: undefined,
      pat: undefined,
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const projectNameValue = watch('projectName');
  const selectedLanguages = watch('languages');

  const { onChange: onEmailAddressChange, ...emailAddressRegisterProps } =
    register('emailAddress');
  const newUserInput = watch('emailAddress');
  const defaultLang = watch('defaultLanguage');

  const { onChange: onRepoOwnerChange, ...repoOwnerRegisterProps } =
    register('repoOwner');

  const { onChange: onRepoNameChange, ...repoNameRegisterProps } =
    register('repoName');

  const { onChange: onRepoPATChange, ...repoPATRegisterProps } =
    register('pat');

  const canSubmit = React.useMemo(() => {
    const allUsersHaveRoles = projectUsers.every((user) => !!user.role);

    return isValid && allUsersHaveRoles && isValidateGitRepoSuccess;
  }, [projectUsers, isValid, isValidateGitRepoSuccess]);

  const handleGitFieldBlur = () => {
    const { repoName, repoOwner, pat } = getValues();

    if (
      repoName &&
      repoOwner &&
      pat &&
      !errors.repoName &&
      !errors.repoOwner &&
      !errors.pat
    ) {
      validateGitRepo();
    }
  };

  return (
    <>
      <LoadingMessage
        isLoading={isLanguagesFetching || isProjectRolesFetching}
      />
      <ErrorMessagePopup
        isError={isLanguagesError}
        error={languagesError}
        retry={refetchLanguages}
      />
      <ErrorMessagePopup
        isError={isProjectRolesError}
        error={projectRolesError}
        retry={refetchProjectRoles}
      />
      <ErrorMessagePopup
        isError={isProjectCreationError}
        error={projectCreationError}
        retry={submitProject}
      />
      <ErrorMessagePopup isError={isNewUserError} error={newUserError} />
      <Heading>Create Project</Heading>
      <Card size='lg'>
        <div style={{ display: 'flex', gap: '32px' }}>
          <div style={{ width: '60%' }}>
            <h2 style={{ margin: '0 0 16px 0' }}>Project Details</h2>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
              }}
            >
              <FormControl>
                <FormLabelWithIcon
                  completed={!errors.projectName && !!projectNameValue}
                  errored={!!errors.projectName}
                  required
                >
                  Project Name
                </FormLabelWithIcon>
                <Input
                  placeholder='Project Name...'
                  {...register('projectName')}
                  error={!!errors.projectName}
                  disabled={isProjectCreationLoading}
                />
                {!!errors.projectName && (
                  <FormHelperText
                    sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                  >
                    {errors.projectName.message}
                  </FormHelperText>
                )}
              </FormControl>
              <div>
                <div style={{ display: 'flex', gap: '24px' }}>
                  <div style={{ minWidth: '35%' }}>
                    <FormLabelWithIcon
                      completed={!errors.defaultLanguage && !!defaultLang}
                      errored={!!errors.defaultLanguage}
                      required
                    >
                      Default Language
                    </FormLabelWithIcon>
                    <Skeleton
                      variant='rectangular'
                      loading={isLanguagesFetching}
                    >
                      <FormControl>
                        <Controller
                          name='defaultLanguage'
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              placeholder='Default Language...'
                              options={languages ?? []}
                              getOptionLabel={(option) => option.name}
                              isOptionEqualToValue={(option, value) =>
                                option.code === value.code
                              }
                              error={!!errors.defaultLanguage}
                              disabled={
                                isLanguagesError || isProjectCreationLoading
                              }
                              renderOption={(props, option) => (
                                <AutocompleteOption {...props}>
                                  <ListItemDecorator>
                                    <CountryFlag languageCode={option.code} />
                                  </ListItemDecorator>
                                  <ListItemContent>
                                    {option.name}
                                  </ListItemContent>
                                </AutocompleteOption>
                              )}
                              startDecorator={
                                value ? (
                                  <CountryFlag languageCode={value} />
                                ) : undefined
                              }
                              onChange={(event, newValue) => {
                                onChange(newValue?.code);
                                // remove selected default language from selected langs
                                setValue(
                                  'languages',
                                  selectedLanguages.filter(
                                    (lang) => lang.code !== newValue?.code,
                                  ),
                                );
                              }}
                            />
                          )}
                        />
                        {!!errors.defaultLanguage && (
                          <FormHelperText
                            sx={{
                              color: joyUiTheme.vars.palette.danger[500],
                            }}
                          >
                            {errors.defaultLanguage.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Skeleton>
                  </div>
                  <div style={{ minWidth: '65%' }}>
                    <FormLabelWithIcon
                      completed={
                        !errors.languages && selectedLanguages.length > 0
                      }
                      errored={!!errors.languages}
                      required
                    >
                      Other Supported Languages
                    </FormLabelWithIcon>
                    <div style={{ display: 'flex', gap: '8px', flexGrow: 1 }}>
                      <div style={{ minWidth: '60%', maxWidth: '60%' }}>
                        <FormControl>
                          <Controller
                            name='languages'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Skeleton
                                variant='rectangular'
                                loading={isLanguagesFetching}
                              >
                                <Autocomplete
                                  multiple
                                  placeholder='Other Supported Languages...'
                                  limitTags={3}
                                  options={
                                    languages?.filter(
                                      (lang) => lang.code !== defaultLang,
                                    ) ?? []
                                  }
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    option.code === value.code
                                  }
                                  error={!!errors.languages}
                                  disabled={
                                    isLanguagesError || isProjectCreationLoading
                                  }
                                  renderOption={(props, option) => (
                                    <AutocompleteOption {...props}>
                                      <ListItemDecorator>
                                        <CountryFlag
                                          languageCode={option.code}
                                        />
                                      </ListItemDecorator>
                                      <ListItemContent>
                                        {option.name}
                                      </ListItemContent>
                                    </AutocompleteOption>
                                  )}
                                  value={value}
                                  onChange={(event, newValue) => {
                                    onChange(newValue);
                                  }}
                                />
                              </Skeleton>
                            )}
                          />
                          {!!errors.languages && (
                            <FormHelperText
                              sx={{
                                color: joyUiTheme.vars.palette.danger[500],
                              }}
                            >
                              {errors.languages.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns:
                            'repeat(auto-fill, minmax(24px, 1fr))',
                          gridAutoRows: '24px',
                          justifyContent: 'start',
                          gap: '6px',
                          minWidth: '30%',
                        }}
                      >
                        {selectedLanguages.map((language) => (
                          <div
                            key={language.code}
                            style={{
                              width: '24px',
                              height: '24px',
                              margin: '6px 0',
                            }}
                          >
                            <CountryFlag
                              languageCode={language.code}
                              size='24px'
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <FormLabelWithIcon
                  style={{ marginBottom: 0 }}
                  completed={isValidateGitRepoSuccess}
                  errored={
                    !!errors.repoName ||
                    !!errors.repoOwner ||
                    isValidateGitRepoError
                  }
                  loading={isValidateGitRepoLoading}
                  required
                >
                  GitHub Repository
                </FormLabelWithIcon>
                <FormHelperText sx={{ marginBottom: '8px' }}>
                  <div>
                    <div>
                      Please provide the URL of the GitHub Repository that has
                      been created for OneTranslate to store the project's
                      translation files
                    </div>
                    <div>
                      <b>
                        Note: This repository should be empty to avoid adverse
                        effects
                      </b>
                    </div>
                    <div>
                      <i>Example: https://github.com/namespace/project</i>
                    </div>
                  </div>
                </FormHelperText>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <div>https://github.com/</div>
                  <FormControl>
                    <Input
                      placeholder='Namespace...'
                      {...repoOwnerRegisterProps}
                      error={!!errors.repoOwner}
                      disabled={isProjectCreationLoading}
                      onBlur={handleGitFieldBlur}
                      onChange={(e) => {
                        onRepoOwnerChange(e);
                        resetValidateGitRepo();
                      }}
                    />
                  </FormControl>
                  <div>/</div>
                  <FormControl>
                    <Input
                      placeholder='Repository Name...'
                      {...repoNameRegisterProps}
                      error={!!errors.repoName}
                      disabled={isProjectCreationLoading}
                      onBlur={handleGitFieldBlur}
                      onChange={(e) => {
                        onRepoNameChange(e);
                        resetValidateGitRepo();
                      }}
                    />
                  </FormControl>
                </div>
                {!!errors.repoOwner && (
                  <FormHelperText
                    sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                  >
                    {errors.repoOwner.message}
                  </FormHelperText>
                )}
                {!!errors.repoName && (
                  <FormHelperText
                    sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                  >
                    {errors.repoName.message}
                  </FormHelperText>
                )}
              </div>
              <FormControl>
                <FormLabelWithIcon
                  completed={isValidateGitRepoSuccess}
                  errored={!!errors.pat || isValidateGitRepoError}
                  loading={isValidateGitRepoLoading}
                  required
                >
                  GitHub Personal Access Token
                </FormLabelWithIcon>
                <FormHelperText sx={{ marginBottom: '8px' }}>
                  Please provide a GitHub Personal Access Token that has rights
                  to read from and push to the repository given above
                </FormHelperText>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Input
                    placeholder='GitHub Personal Access Token...'
                    {...repoPATRegisterProps}
                    error={!!errors.pat}
                    onBlur={handleGitFieldBlur}
                    onChange={(e) => {
                      onRepoPATChange(e);
                      resetValidateGitRepo();
                    }}
                    sx={{ display: 'flex', flexGrow: 1 }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title='This token will be encrypted using AES-256 encryption to ensure security'
                      placement='top'
                      arrow
                    >
                      <div style={{ display: 'flex', gap: '8px' }}>
                        <FontAwesomeIcon
                          icon={faLock}
                          size='lg'
                          color={
                            joyUiTheme.colorSchemes.light.palette.warning[500]
                          }
                        />
                        <Typography fontWeight={'bold'} color='warning'>AES-256</Typography>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {!!errors.pat && (
                  <FormHelperText
                    sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                  >
                    {errors.pat.message}
                  </FormHelperText>
                )}
              </FormControl>
              {isValidateGitRepoError && (
                <div>
                  <GitValidationErrorMessage
                    error={validateGitRepoError}
                    retry={validateGitRepo}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={{ width: '1px', display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: '100%',
                height: '90%',
                backgroundColor: '#B3B3B3',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              gap: '16px',
            }}
          >
            <h2 style={{ margin: 0 }}>Users</h2>
            <div>
              <div style={{ display: 'flex', gap: '32px' }}>
                <FormControl sx={{ width: '100%' }}>
                  <Input
                    placeholder='Enter Email Address...'
                    {...emailAddressRegisterProps}
                    onChange={(e) => {
                      onEmailAddressChange(e);
                      setIsNewUserNotFoundError(false);
                      setNewUserAlreadyAdded(false);
                    }}
                    error={!!errors.emailAddress}
                    disabled={isNewUserLoading || isProjectCreationLoading}
                  />
                </FormControl>

                <Button
                  startDecorator={<FontAwesomeIcon icon={faPlus} />}
                  disabled={!!errors.emailAddress || !newUserInput}
                  loading={isNewUserLoading}
                  onClick={() => searchForUser(newUserInput as string)}
                >
                  Add
                </Button>
              </div>
              {isNewUserNotFoundError && (
                <FormHelperText
                  sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                >
                  User Not Found. Please try another email address
                </FormHelperText>
              )}
              {newUserAlreadyAdded && (
                <FormHelperText
                  sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                >
                  User has already been added to the project
                </FormHelperText>
              )}
              {!!errors.emailAddress && (
                <FormHelperText
                  sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                >
                  {errors.emailAddress.message}
                </FormHelperText>
              )}
            </div>
            <div
              style={{
                marginTop: '16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              {projectUsers.map((projectUser) => (
                <div key={projectUser.user.id} style={{ display: 'flex' }}>
                  <div style={{ width: '50%', wordBreak: 'break-all' }}>
                    <ProfileName user={projectUser.user} variant='large' />
                  </div>
                  {projectUser.user.id === user?.sub ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      Project Administrator
                      <FontAwesomeIcon
                        icon={faCrown}
                        color={
                          joyUiTheme.colorSchemes.light.palette.warning[400]
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          width: '40%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Skeleton
                          variant='rectangular'
                          loading={isProjectRolesFetching}
                        >
                          <Select
                            sx={{ width: '100%' }}
                            disabled={
                              isProjectRolesFetching || isProjectRolesError
                            }
                            placeholder='Select role...'
                            onChange={(_event, newValue) => {
                              changeUserRole(
                                projectUser.user.id,
                                newValue as ProjectRole,
                              );
                            }}
                          >
                            {projectRoles?.map((role) => (
                              <Option value={role.id} key={role.id}>
                                {role.description}
                              </Option>
                            ))}
                          </Select>
                        </Skeleton>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexGrow: 1,
                          alignItems: 'center',
                        }}
                      >
                        <button
                          className={css(commonStyles.nativeReset)}
                          onClick={() =>
                            removeUserFromProjectList(projectUser.user)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            color={
                              joyUiTheme.colorSchemes.dark.palette.danger[500]
                            }
                          />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
      <div
        style={{
          marginTop: '32px',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '16px',
        }}
      >
        <Button
          color='danger'
          size='lg'
          startDecorator={<FontAwesomeIcon icon={faTrashCan} />}
          onClick={() => navigate(Page.Dashboard)}
          disabled={isProjectCreationLoading}
        >
          Cancel
        </Button>
        <Button
          size='lg'
          startDecorator={<FontAwesomeIcon icon={faCheck} />}
          disabled={!canSubmit || isProjectCreationLoading}
          loading={isProjectCreationLoading}
          onClick={() => submitProject()}
        >
          Create
        </Button>
      </div>
    </>
  );
};

export default CreateProject;
