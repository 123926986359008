import {
  faAngleLeft,
  faCheck,
  faCircleInfo,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Link, Select, Skeleton, Typography, Option } from '@mui/joy';
import React from 'react';
import { Page } from '../../types/navigation';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Heading from '../../components/heading/heading';
import BasicReleaseDetails from '../../components/basic-release-details/basic-release-details';
import generalUtil from '../../util/general';
import useAuth from '../../util/auth-hook';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import projectService from '../../service/project';
import LoadingMessage from '../../components/loading-message/loading-message';
import ErrorMessagePopup from '../../components/error-message/error-message-popup/error-message-popup';
import { AppRole, ProjectRole } from '../../types/user';
import {
  BasicReleaseDetails as BasicReleaseDetailsType,
  ReleaseStatus,
} from '../../types/project';
import TranslationsTable from '../../components/translations-table/translations-table';
import { LanguageData } from '../../types/misc';
import Subtitle from '../../components/subtitle/subtitle';
import CountryFlag from '../../components/country-flag/country-flag';
import theme from '../../styles/theme';
import Card from '../../components/card/card';
import ApproveReleaseConfirmationModal from '../../components/approve-release-confirmation-modal/approve-release-confirmation-modal';
import Message from '../../components/message/message';
import LanguageTranslationStatus from '../../components/language-translation-status/language-translation-status';
import translationService from '../../service/translation';
import gitService from '../../service/git';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import fileDownload from 'js-file-download';

const determineCanApprove = (
  basicReleaseDetails?: BasicReleaseDetailsType,
  userPerms?: AppRole[],
) => {
  if (
    basicReleaseDetails?.status.code === ReleaseStatus.Translating ||
    basicReleaseDetails?.status.code ===
      ReleaseStatus.ClientReviewPostTranslation
  ) {
    return (
      userPerms?.includes(AppRole.Admin) ||
      basicReleaseDetails.userProjectRole === ProjectRole.Admin ||
      basicReleaseDetails.userProjectRole === ProjectRole.TranslationCoordinator
    );
  }

  return false;
};

const determineCanEditValues = (
  basicReleaseDetails?: BasicReleaseDetailsType,
  userPerms?: AppRole[],
) => {
  if (basicReleaseDetails?.status.code === ReleaseStatus.Translating) {
    return (
      userPerms?.includes(AppRole.Admin) ||
      basicReleaseDetails.userProjectRole === ProjectRole.Admin ||
      basicReleaseDetails.userProjectRole ===
        ProjectRole.TranslationCoordinator ||
      basicReleaseDetails.userProjectRole === ProjectRole.Translator
    );
  }

  if (
    basicReleaseDetails?.status.code ===
    ReleaseStatus.ClientReviewPostTranslation
  ) {
    return (
      userPerms?.includes(AppRole.Admin) ||
      basicReleaseDetails.userProjectRole === ProjectRole.Admin ||
      basicReleaseDetails.userProjectRole === ProjectRole.TranslationCoordinator
    );
  }

  return false;
};

const determineCanReview = (
  basicReleaseDetails?: BasicReleaseDetailsType,
  userPerms?: AppRole[],
) => {
  if (
    basicReleaseDetails?.status.code ===
    ReleaseStatus.ClientReviewPostTranslation
  ) {
    return (
      userPerms?.includes(AppRole.Admin) ||
      basicReleaseDetails.userProjectRole === ProjectRole.Admin ||
      basicReleaseDetails.userProjectRole === ProjectRole.TranslationCoordinator
    );
  }

  return false;
};

const ReleaseTranslations = () => {
  const { projectId, releaseId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [language, setLanguage] = React.useState<LanguageData | undefined>();
  const [fullWidth, setFullWidth] = React.useState(false);
  const [isApproveConfirmationOpen, setIsApproveConfirmationOpen] =
    React.useState(false);
  const [hasStatusChanged, setHasStatusChanged] = React.useState(false);
  const [allReviewed, setAllReviewed] = React.useState(false);

  const { getAccessTokenSilently, userPerms } = useAuth();

  const {
    data: basicReleaseDetails,
    isFetching: isBasicReleaseDetailsLoading,
    isError: isBasicReleaseDetailsError,
    error: basicReleaseDetailsError,
    refetch: refetchBasicReleaseDetails,
  } = useQuery(
    ['basic-release-details', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getBasicReleaseDetails(
        accessToken,
        Number(releaseId),
        Number(projectId),
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const {
    data: projectDetails,
    isFetching: isProjectDetailsFetching,
    isError: isProjectDetailsError,
    error: projectDetailsError,
    refetch: refetchProjectDetails,
  } = useQuery(
    ['project-details-no-versions', projectId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await projectService.getProjectDetails(
        accessToken,
        Number(projectId),
        false,
        true,
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: approveResponse,
    isLoading: isApproveLoading,
    isError: isApproveError,
    isSuccess: isApproveSuccess,
    error: approveError,
    mutate: submitApproval,
    reset: resetSubmitApproval,
  } = useMutation(
    async (status?: ReleaseStatus) => {
      const accessToken = await getAccessTokenSilently();

      if (status === ReleaseStatus.ClientReviewPostTranslation) {
        return await gitService.release(accessToken, Number(releaseId));
      } else {
        return await projectService.approveRelease(
          accessToken,
          Number(releaseId),
          Number(projectId),
        );
      }
    },
    {
      onSuccess: (release) => {
        if (release.status !== ReleaseStatus.Released) {
          closePage();
        }
      },
    },
  );

  const {
    data: languageTranslationStats,
    isFetching: isLanguageTranslationStatsLoading,
    isError: isLanguageTranslationStatsError,
    error: languageTranslationStatsError,
    refetch: refetchLanguageTranslationStats,
  } = useQuery(
    ['language-translation-stats', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await translationService.getReleaseTranslationStats(
        accessToken,
        Number(releaseId),
      );
    },
    {
      staleTime: 1000 * 60 * 5,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: isReleaseFileDownloading,
    isError: isReleaseFileDownloadError,
    error: releaseFileDownloadError,
    mutate: downloadReleaseFile,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      return await gitService.downloadReleaseFiles(
        accessToken,
        Number(releaseId),
      );
    },
    {
      onSuccess: (data) => {
        fileDownload(data.file, data.fileName);
      },
    },
  );

  const handleApprovalConfirm = () => {
    submitApproval(basicReleaseDetails?.status.code);
  };

  const handleApprovalCancel = () => {
    setIsApproveConfirmationOpen(false);
    resetSubmitApproval();
  };

  const handleGithubNav = () => {
    if (basicReleaseDetails?.githubUrl) {
      window.open(basicReleaseDetails.githubUrl, '_blank');
    }
  };

  const closePage = () => {
    queryClient.invalidateQueries({
      queryKey: ['project-details', projectId],
    });
    queryClient.invalidateQueries({
      queryKey: ['project-details-no-versions', projectId],
    });
    queryClient.invalidateQueries({
      queryKey: ['basic-release-details', releaseId],
    });
    resetSubmitApproval();
    navigate(`${Page.ProjectRoute}/${projectId}`);
  };

  const canEditValues = React.useMemo(
    () => determineCanEditValues(basicReleaseDetails, userPerms),
    [basicReleaseDetails, userPerms],
  );

  const canApprove = React.useMemo(
    () => determineCanApprove(basicReleaseDetails, userPerms),
    [basicReleaseDetails, userPerms],
  );

  const canReview = React.useMemo(
    () => determineCanReview(basicReleaseDetails, userPerms),
    [basicReleaseDetails, userPerms],
  );

  const handleTranslationUpdate = (statusChange: boolean) => {
    refetchLanguageTranslationStats();
    if (statusChange) {
      setHasStatusChanged(true);
      refetchBasicReleaseDetails();
      refetchProjectDetails();
    }
  };

  const handleTranslationReview = (allRevd: boolean) => {
    setAllReviewed(allRevd);
    refetchLanguageTranslationStats();
  }

  React.useEffect(() => {
    const hasStatusChangedQueryParam = queryParams.get('statusChanged');
    if (
      hasStatusChangedQueryParam === 'true' &&
      basicReleaseDetails?.status.code ===
        ReleaseStatus.ClientReviewPostTranslation
    ) {
      setHasStatusChanged(true);
    }
  }, [queryParams, basicReleaseDetails]);

  React.useEffect(() => {
    const previewLang = queryParams.get('lang');
    const langData = projectDetails?.langs.find(
      (data) => data.code === previewLang,
    );

    if (langData) {
      setLanguage(langData);
    } else if (projectDetails) {
      queryParams.delete('lang');
      setQueryParams(queryParams);
    }
  }, [queryParams, projectDetails, setQueryParams]);

  return (
    <>
      <ApproveReleaseConfirmationModal
        isOpen={isApproveConfirmationOpen}
        handleCancel={handleApprovalCancel}
        handleConfirm={handleApprovalConfirm}
        handleConfirmationClose={closePage}
        releaseName={basicReleaseDetails?.versionName}
        status={basicReleaseDetails?.status}
        isLoading={isApproveLoading}
        isError={isApproveError}
        error={approveError}
        showConfirmation={
          isApproveSuccess &&
          basicReleaseDetails?.status.code ===
            ReleaseStatus.ClientReviewPostTranslation
        }
        gitUrl={approveResponse?.gitUrl}
        allReviewed={allReviewed}
      />
      <LoadingMessage
        isLoading={
          isBasicReleaseDetailsLoading ||
          isProjectDetailsFetching ||
          isLanguageTranslationStatsLoading
        }
      />
      <ErrorMessagePopup
        isError={isBasicReleaseDetailsError}
        error={basicReleaseDetailsError}
        retry={refetchBasicReleaseDetails}
      />
      <ErrorMessagePopup
        isError={isProjectDetailsError}
        error={projectDetailsError}
        retry={refetchProjectDetails}
      />
      <ErrorMessagePopup
        isError={isLanguageTranslationStatsError}
        error={languageTranslationStatsError}
        retry={refetchLanguageTranslationStats}
      />
      <ErrorMessagePopup
        isError={isReleaseFileDownloadError}
        error={releaseFileDownloadError}
        retry={downloadReleaseFile}
      />
      <Link
        startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
        fontSize={'18px'}
        href={`${Page.ProjectRoute}/${projectId}`}
      >
        Project Details
      </Link>
      <Heading style={{ margin: '8px 0px 24px' }}>Translations</Heading>
      <div style={{ display: 'flex', gap: '24px', marginBottom: '24px' }}>
        <div style={{ width: '75%', display: 'flex', gap: '24px' }}>
          <div>
            <Typography fontSize='14px'>
              <>
                Here you can review all translations that are included in this
                release, for all languages supported by the project.
                {canEditValues && (
                  <>
                    {' '}
                    You can also make any changes to the translated value for
                    any translation in any language.
                  </>
                )}
                {canApprove && (
                  <>
                    {' '}
                    Once you're happy, you can approve and release this version.
                  </>
                )}
              </>
            </Typography>
          </div>
          {canApprove && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}
            >
              <Button
                startDecorator={<FontAwesomeIcon icon={faCheck} />}
                color='success'
                onClick={() => setIsApproveConfirmationOpen(true)}
              >
                Approve
              </Button>
            </div>
          )}
          {basicReleaseDetails?.status.code === ReleaseStatus.Released && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}
            >
              <Button
                startDecorator={<FontAwesomeIcon icon={faGithub} />}
                sx={{ textWrap: 'nowrap' }}
                color='neutral'
                disabled={!basicReleaseDetails.githubUrl}
                onClick={handleGithubNav}
              >
                See on GitHub
              </Button>
              <Button
                startDecorator={<FontAwesomeIcon icon={faDownload} />}
                sx={{ textWrap: 'nowrap' }}
                onClick={() => downloadReleaseFile()}
                loading={isReleaseFileDownloading}
              >
                Download JSON
              </Button>
            </div>
          )}
        </div>
        <BasicReleaseDetails
          projectName={generalUtil.formatData(basicReleaseDetails?.projectName)}
          versionName={generalUtil.formatData(basicReleaseDetails?.versionName)}
          isLoading={isBasicReleaseDetailsLoading}
        />
      </div>
      <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '16px',
            height: '96px',
          }}
        >
          <Card sx={{ flexGrow: 1 }}>
            <div style={{ width: '250px' }}>
              <Subtitle>Language</Subtitle>
              <Skeleton
                variant='rectangular'
                loading={isProjectDetailsFetching}
              >
                <Select
                  placeholder='Language...'
                  startDecorator={
                    language ? (
                      <CountryFlag languageCode={language.code} />
                    ) : undefined
                  }
                  value={language}
                  onChange={(event, newValue) => {
                    setLanguage(newValue as LanguageData);
                  }}
                >
                  {projectDetails?.langs?.map((lang) => (
                    <Option value={lang} key={lang.code}>
                      <CountryFlag languageCode={lang.code} />
                      {lang.name}
                    </Option>
                  ))}
                </Select>
              </Skeleton>
            </div>
          </Card>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              maxWidth: 'calc(25% - 16px)',
            }}
          >
            <LanguageTranslationStatus
              languageTranslationStats={languageTranslationStats}
              onCollapse={(collapsed) => setFullWidth(collapsed)}
            />
          </div>
        </div>
        <div
          style={{
            width: fullWidth ? '100%' : '75%',
            transition: 'all 0.5s ease',
          }}
        >
          {hasStatusChanged && (
            <div style={{ marginBottom: '16px' }}>
              <Message
                variant='success'
                title='Release Complete'
                onClose={() => setHasStatusChanged(false)}
              >
                <div>
                  <Typography sx={{ color: 'inherit' }}>
                    All translations for this release have been completed. The
                    release has therefore moved forward to Client Review
                    automatically.{' '}
                    {!canEditValues &&
                      'As such, you can no longer make any changes.'}
                  </Typography>
                </div>
              </Message>
            </div>
          )}
          {language ? (
            <TranslationsTable
              language={language}
              defaultLanguage={projectDetails?.defaultLang.code}
              releaseId={Number(releaseId)}
              editable={canEditValues}
              onTranslationUpdate={handleTranslationUpdate}
              showReview={canReview}
              onReview={handleTranslationReview}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                marginTop: '16px',
                color: theme.colour.grey,
              }}
            >
              <FontAwesomeIcon icon={faCircleInfo} size='2x' />
              <Typography sx={{ color: 'inherit' }}>
                Select a language to review translations
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReleaseTranslations;
