import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { AppRole } from '../types/user';
import authUtil from './auth';

/**
 *
 * Wrapper for useAuth0() that also includes a user's permissions
 */
const useAuth = () => {
  const auth0 = useAuth0();

  const { getIdTokenClaims, isAuthenticated, user } = auth0;
  const [userPerms, setUserPerms] = React.useState<AppRole[] | undefined>(
    undefined,
  );

  const getUserPerms = React.useCallback(async (): Promise<AppRole[]> => {
    if (isAuthenticated) {
      const tokenClaims = await getIdTokenClaims();
      return tokenClaims?.[authUtil.ROLE_ID_TOKEN_CLAIM_KEY] ?? [];
    }
    return [];
  }, [getIdTokenClaims, isAuthenticated]);

  React.useEffect(() => {
    getUserPerms().then(setUserPerms).catch(console.error);
  }, [getUserPerms, isAuthenticated, user?.sub]);

  return {
    ...auth0,
    userPerms,
  };
};

export default useAuth;
