import { StyleSheet } from 'aphrodite';
import joyUiTheme from '../../styles/joyui-theme';

const animatedCheckCirclesStyles = StyleSheet.create({
  base: {
    borderRadius: '2000px',
    backgroundColor: joyUiTheme.colorSchemes.light.palette.success[200],
  },
  whiteUnderlay: {
    borderRadius: '2000px',
    backgroundColor: 'white',
    width: '50px',
    height: '50px',
    position: 'absolute',
    zIndex: 4,
  },
  sizeAnimation: {
    animationName: {
      '0%': {
        width: '0px',
        height: '0px',
      },
      '100%': {
        width: '150px',
        height: '150px',
      },
    },
    animationDuration: '2s',
    animationIterationCount: 1,
  },
  opacityAnimation: {
    animationName: {
      '0%': {
        opacity: 1,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
    animationDuration: '2s',
    animationIterationCount: 1,
  },
  innerContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '150px',
    height: '100px',
    overflow: 'visible',
    transform: 'translateX(-25px)'
  },
  outerContainer: {
    position: 'relative',
    height: '100px',
    width: '100px',
    padding: '16px',
    overflow: 'visible',
  },
});

export default animatedCheckCirclesStyles;
