import { Link } from '@mui/joy';
import React from 'react';
import axios, { AxiosError } from 'axios';
import Message from '../message/message';

export type ErrorMessageProps = {
  isError: boolean;
  error?: unknown;
  retry?: () => void;
  children?: React.ReactNode;
};

const ErrorMessage = ({ isError, error, retry, children }: ErrorMessageProps) => {
  return (
    <>
      {isError && (
        <Message variant='danger' title='Something went wrong'>
          <div>
            <div>
              {error instanceof AxiosError
                ? (error.response?.data?.error?.message ?? error?.message)
                : 'An unknown error occurred'}
            </div>
            <div>
              {children}
            </div>
            {retry ? (
              <Link onClick={retry} color='danger' sx={{ fontSize: '14px' }}>
                Please click here to try again
              </Link>
            ) : (
              <>Please try again</>
            )}
          </div>
          {axios.isAxiosError(error) && (
            <div style={{ fontSize: '12px' }}>
              <div>
                Correlation ID: {error.config?.headers['correlation-id']}
              </div>
              <div>Error Code: {error.response?.data?.error?.code}</div>
            </div>
          )}
        </Message>
      )}
    </>
  );
};

export default ErrorMessage;
