import React from 'react';
import { Language, LanguageData } from '../../types/misc';
import Card from '../card/card';
import {
  Checkbox,
  CircularProgress,
  IconButton,
  Skeleton,
  Table,
  Tooltip,
  Typography,
} from '@mui/joy';
import { css } from 'aphrodite';
import keysTableStyles from '../keys-table/keys-table.styles';
import useAuth from '../../util/auth-hook';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import translationService from '../../service/translation';
import LoadingMessage from '../loading-message/loading-message';
import ErrorMessagePopup from '../error-message/error-message-popup/error-message-popup';
import { User } from '../../types/user';
import ProfileName from '../profile-name/profile-name';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPencil } from '@fortawesome/free-solid-svg-icons';
import EditTranslationModal from '../edit-translation-modal/edit-translation-modal';
import {
  TranslationData,
  UpdateTranslationValueResponse,
} from '../../types/translation';
import AutoTranslatedIndicator from '../auto-translated-indicator/auto-translated-indicator';
import translationsTableStyles from './translations-table.styles';
import theme from '../../styles/theme';
import generalUtil from '../../util/general';
import userService from '../../service/user';
import authUtil from '../../util/auth';

export type TranslationsTableProps = {
  language: LanguageData;
  defaultLanguage?: Language;
  releaseId: number;
  editable: boolean;
  showReview: boolean;
  onTranslationUpdate: (statusChange: boolean) => void;
  onReview?: (allReviewed: boolean) => void;
};

type ReviewKeyInput = {
  reviewed: boolean;
  key?: string;
  keyIndex?: number;
};

const TranslationsTable = ({
  language,
  defaultLanguage,
  releaseId,
  editable,
  showReview,
  onTranslationUpdate,
  onReview,
}: TranslationsTableProps) => {
  const { getAccessTokenSilently, user } = useAuth();

  const [rowEditing, setRowEditing] = React.useState<number | undefined>();
  const [allReviewed, setAllReviewed] = React.useState(false);
  // number = key index | null = all reviewing | undefined = none reviewing
  const [keyReviewing, setKeyReviewing] = React.useState<
    number | null | undefined
  >(undefined);
  const [rows, setRows] = React.useState<TranslationData[]>([]);

  const queryClient = useQueryClient();

  const {
    data: translations,
    isFetching: isTranslationsFetching,
    isError: isTranslationsError,
    error: translationsError,
    refetch: refetchTranslations,
  } = useQuery(
    ['translations', releaseId, language.code],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await translationService.getTranslationValues(
        accessToken,
        Number(releaseId),
        language.code,
      );
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  React.useEffect(() => {
    setRows(translations ?? []);
  }, [translations])

  const {
    data: releaseParticipantsUserData,
    isFetching: isReleaseParticipantsFetching,
    isError: isReleaseParticipantsError,
    error: releaseParticipantsError,
    refetch: refetchReleaseParticipants,
  } = useQuery(
    ['release-participants', releaseId],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const res = await userService.getReleaseParticipants(
        accessToken,
        Number(releaseId),
      );

      return user ? [...res, authUtil.getCurrentUserObject(user)] : res;
    },
    {
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  const {
    isLoading: isReviewKeyLoading,
    isError: isReviewKeyError,
    error: reviewKeyError,
    mutate: reviewKey,
  } = useMutation(
    async (input: ReviewKeyInput) => {
      const accessToken = await getAccessTokenSilently();

      setKeyReviewing(input.keyIndex ?? null);

      return await translationService.reviewTranslation(
        accessToken,
        Number(releaseId),
        input.reviewed,
        language.code,
        input.key,
      );
    },
    {
      onError: () => {
        setKeyReviewing(undefined);
      },
      onSuccess: (_response, input) => {
        const updatedRowData = rows.map((exstData) => {
          if (!input.key || exstData.key === input.key) {
            return {
              ...exstData,
              review: input.reviewed
                ? {
                    user: user?.sub ?? 'unknown',
                    timestamp: new Date().toISOString(),
                  }
                : undefined,
            };
          } else {
            return exstData;
          }
        });
        setRows(updatedRowData);
        queryClient.setQueryData(['translations', releaseId, language.code], updatedRowData);

        if (!input.key) {
          setAllReviewed(input.reviewed);
        }

        if (onReview) {
          onReview(updatedRowData.length > 0 && updatedRowData.every((data) => !!data.review));
        }

        setKeyReviewing(undefined);
      },
    },
  );

  const editRow = (index?: number) => {
    setRowEditing(index);
  };

  const handleTranslationUpdate = (
    response: UpdateTranslationValueResponse,
  ) => {
    queryClient.invalidateQueries({
      queryKey: ['translations', releaseId, language],
    });
    setRows(response.values);
    onTranslationUpdate(response.hasStatusChanged);
  };

  React.useEffect(() => {
    setAllReviewed(
      rows.length > 0 && rows?.every((keyData) => !!keyData.review),
    );
  }, [rows]);

  const translationDataEditing = React.useMemo(() => {
    if (rows && rowEditing !== undefined) {
      return rows[rowEditing];
    }
  }, [rows, rowEditing]);

  return (
    <>
      <LoadingMessage
        isLoading={isTranslationsFetching || isReleaseParticipantsFetching}
      />
      <ErrorMessagePopup
        isError={isTranslationsError}
        error={translationsError}
        retry={refetchTranslations}
      />
      <ErrorMessagePopup
        isError={isReleaseParticipantsError}
        error={releaseParticipantsError}
        retry={refetchReleaseParticipants}
      />
      <ErrorMessagePopup isError={isReviewKeyError} error={reviewKeyError} />
      <EditTranslationModal
        isOpen={rowEditing !== undefined}
        lang={language}
        defaultLang={defaultLanguage}
        releaseId={releaseId}
        translationData={translationDataEditing}
        onClose={() => setRowEditing(undefined)}
        onTranslationUpdate={handleTranslationUpdate}
      />
      <Card data-testid={`translations-table-${language.code}`}>
        <Table>
          <thead>
            <tr data-testid='translations-table-heading'>
              {showReview && (
                <th
                  className={css(keysTableStyles.heading)}
                  style={{ width: '3%' }}
                >
                  <Tooltip
                    title={
                      allReviewed
                        ? 'Mark all as unreviewed'
                        : 'Mark all as reviewed'
                    }
                    placement='top'
                    arrow
                    enterDelay={500}
                  >
                    {isReviewKeyLoading && keyReviewing === null ? (
                      <CircularProgress
                        size='sm'
                        sx={{ '--CircularProgress-size': '20px' }}
                      />
                    ) : (
                      <Checkbox
                        checked={allReviewed}
                        onChange={(event) => {
                          reviewKey({ reviewed: event.target.checked });
                        }}
                      />
                    )}
                  </Tooltip>
                </th>
              )}
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '14%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  Key
                  <Tooltip
                    title='The unique identifier for the translation used within the codebase'
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-key'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: editable ? '20%' : '22%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  Primary Value
                  <Tooltip
                    title={`The initial value in your project's default language`}
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-primaryvalue'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: editable ? '23%' : '25%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  {language.name} Value
                  <Tooltip
                    title={`The translated value of the default value${language.name ? `, in ${language.name}` : ''}. A blank value will not replace any existing values, or will fallback to the primary value if this is a new key`}
                    placement='top'
                    arrow
                    sx={{ maxWidth: '400px' }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-translatedvalue'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '8%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  Placeholder
                  <Tooltip
                    title='Whether the translation contains any placeholders or HTML tags'
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-placeholder'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '15%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  Context
                  <Tooltip
                    title='Context as to where the label appears in the application, or any specifics as to its meaning'
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-context'
                    />
                  </Tooltip>
                </div>
              </th>
              <th
                className={css(keysTableStyles.heading)}
                style={{ width: '13%' }}
              >
                <div className={css(translationsTableStyles.heading)}>
                  Last Modified By
                  <Tooltip
                    title='The name of the user who translated that particular translation, or who last made changes to it'
                    placement='top'
                    arrow
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      color={theme.colour.grey}
                      data-testid='help-translator'
                    />
                  </Tooltip>
                </div>
              </th>
              {editable && (
                <th
                  className={css(keysTableStyles.heading)}
                  style={{ width: '4%' }}
                ></th>
              )}
            </tr>
          </thead>
          <tbody>
            {isTranslationsFetching || isReleaseParticipantsFetching ? (
              <tr>
                <td colSpan={8}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <Skeleton variant='rectangular' height={'50px'} />
                    <Skeleton variant='rectangular' height={'50px'} />
                    <Skeleton variant='rectangular' height={'50px'} />
                    <Skeleton variant='rectangular' height={'50px'} />
                  </div>
                </td>
              </tr>
            ) : (
              rows?.map((data, index) => (
                <tr
                  key={`${data.id}-${data.key}`}
                  data-testid={`translation-table-row-${data.id}`}
                >
                  {showReview && (
                    <td
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      data-testid={`translation-table-row-${data.id}-review`}
                    >
                      <Tooltip
                        title={
                          <>
                            {data.review ? (
                              <div style={{ textAlign: 'center' }}>
                                <div>Mark as unreviewed</div>
                                <div>
                                  <i>
                                    Reviewed by{' '}
                                    {
                                      releaseParticipantsUserData?.find(
                                        (usr) => usr.id === data.review?.user,
                                      )?.name.full
                                    }{' '}
                                    {generalUtil.getTimestampRelativeToNow(
                                      data.review.timestamp,
                                    )}
                                  </i>
                                </div>
                              </div>
                            ) : (
                              <div>Mark as reviewed</div>
                            )}
                          </>
                        }
                        placement='top'
                        arrow
                        enterDelay={500}
                      >
                        {isReviewKeyLoading && keyReviewing === index ? (
                          <CircularProgress
                            size='sm'
                            sx={{ '--CircularProgress-size': '20px' }}
                          />
                        ) : (
                          <Checkbox
                            checked={!!data.review}
                            defaultChecked={!!data.review}
                            disabled={isReviewKeyLoading}
                            onChange={(e) => {
                              reviewKey({
                                reviewed: e.target.checked,
                                key: data.key,
                                keyIndex: index,
                              });
                            }}
                          />
                        )}
                      </Tooltip>
                    </td>
                  )}
                  <td>
                    <div style={{ display: 'flex' }}>
                      <Typography
                        fontFamily={'monospace'}
                        variant='soft'
                        sx={{ overflowWrap: 'anywhere' }}
                      >
                        {data.key}
                      </Typography>
                    </div>
                  </td>
                  <td>
                    <div>{data.primaryValue}</div>
                  </td>
                  <td>
                    {data.translatedValue ? (
                      <div>{data.translatedValue}</div>
                    ) : (
                      <div
                        style={{
                          color: theme.colour.grey,
                          fontStyle: 'italic',
                        }}
                      >
                        {'( blank )'}
                      </div>
                    )}
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox checked={data.placeholder} disabled />
                    </div>
                  </td>
                  <td>
                    <div>{data.context}</div>
                  </td>
                  <td>
                    <div>
                      {data.autoTranslated ? (
                        <AutoTranslatedIndicator
                          initiatorName={
                            releaseParticipantsUserData?.find(
                              (user) => user.id === data.updatedBy,
                            )?.name.full
                          }
                        />
                      ) : (
                        <ProfileName
                          user={releaseParticipantsUserData?.find(
                            (user) => user.id === data.updatedBy,
                          )}
                          variant='xsmall'
                        />
                      )}
                    </div>
                  </td>
                  {editable && (
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <IconButton
                          color='primary'
                          variant='solid'
                          aria-label='Edit'
                          size='sm'
                          onClick={() => editRow(index)}
                          disabled={rowEditing !== undefined}
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </IconButton>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default TranslationsTable;
