import { AxiosError } from "axios";
import { BulkAutoTranslateResponse, ReleaseTranslationValueStats, TranslationData, TranslationKeyData, TranslationKeyInput, TranslationValueData, UpdateTranslationValueResponse } from "../types/translation";
import axiosClient from "../util/axios";
import { getEnvVariables } from "../util/env-variables";
import { Language, LanguageData } from "../types/misc";

const translationService = {
    getTranslationKeys: async (accessToken: string, releaseId: number): Promise<TranslationKeyData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/translation/keys/${releaseId}`, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as TranslationKeyData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    updateTranslationKeys: async (accessToken: string, releaseId: number, data: TranslationKeyInput[]): Promise<TranslationKeyData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.patch(`${apiRootUrl}/translation/keys/${releaseId}`, { keys: data }, { headers: { Authorization: `Bearer ${accessToken}`} });

            return response.data.data as TranslationKeyData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    deleteTranslationKeys: async (accessToken: string, releaseId: number, keys: string[]): Promise<TranslationKeyData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.delete(`${apiRootUrl}/translation/keys/${releaseId}`, { data: { keys }, headers: { Authorization: `Bearer ${accessToken}`} });

            return response.data.data as TranslationKeyData[];
        } catch (err) {
            return Promise.reject(err as AxiosError);
        }
    },
    addTranslationKeys: async (accessToken: string, releaseId: number, keys: TranslationKeyInput[]): Promise<TranslationKeyData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.post(`${apiRootUrl}/translation/keys/${releaseId}`, { keys }, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as TranslationKeyData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    getUntranslatedLangs: async (accessToken: string, releaseId: number): Promise<LanguageData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/translation/languages/${releaseId}`, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as LanguageData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    getTranslationValues: async (accessToken: string, releaseId: number, lang: Language): Promise<TranslationData[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/translation/values/${releaseId}?lang=${lang}`, { headers: { Authorization: `Bearer ${accessToken}` }});

            return response.data.data as TranslationData[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    updateTranslationValues: async (accessToken: string, releaseId: number, lang: Language, values: TranslationValueData[]): Promise<UpdateTranslationValueResponse> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.patch(`${apiRootUrl}/translation/values/${releaseId}`, { lang, values }, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as UpdateTranslationValueResponse;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    getReleaseTranslationStats: async (accessToken: string, releaseId: number): Promise<ReleaseTranslationValueStats[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/translation/stats/${releaseId}`, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as ReleaseTranslationValueStats[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    autoTranslateLangInRelease: async (accessToken: string, releaseId: number, lang: Language): Promise<BulkAutoTranslateResponse> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.post(`${apiRootUrl}/translation/bulk-autotranslate/${releaseId}?lang=${lang}`, undefined, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as BulkAutoTranslateResponse;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    reviewTranslation: async (accessToken: string, releaseId: number, reviewed: boolean, lang?: Language, key?: string): Promise<void> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            await axiosClient.post(`${apiRootUrl}/translation/review/${releaseId}`, key ? { key } : undefined, { headers: { Authorization: `Bearer ${accessToken}` }, params: { reviewed, lang } });
        } catch (err) {
            return Promise.reject(err as Error);
        }
    }
};

export default translationService;