import { faCheckCircle, faCircleXmark, faExclamationCircle, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ReleasePunctuality } from '../project-version/project-version';
import { useTheme } from '@mui/joy';

export type PunctualityIndicatorProps = {
  punctuality: ReleasePunctuality;
  'data-testid'?: string;
};

const PunctualityIndicator = ({
  punctuality,
  'data-testid': dataTestId,
}: PunctualityIndicatorProps) => {
  const joyUiTheme = useTheme();

  const iconColour = React.useMemo(() => {
    return joyUiTheme.colorSchemes.light.palette[
      punctuality === 'ontime'
        ? 'success'
        : punctuality === 'late'
          ? 'danger'
          : 'warning'
    ][punctuality === 'today' ? 400 : 500];
  }, [punctuality, joyUiTheme]);

  return (
    <div style={{ position: 'relative', width: '40px', height: '44px' }}>
      <div style={{ position: 'absolute', bottom: '1px', right: '1px', zIndex: 2 }}>
        <FontAwesomeIcon icon={punctuality === 'ontime' ? faCheckCircle : punctuality === 'late' ? faCircleXmark : faExclamationCircle} color={iconColour} />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          height: '28px',
          width: '20px',
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            height: '20px',
            width: '20px',
            borderRadius: '20px',
          }}
        />
      </div>
      <div>
        <FontAwesomeIcon
          icon={faClock}
          data-testid={dataTestId}
          color={iconColour}
          size='2x'
        />
      </div>
    </div>
  );
};

export default PunctualityIndicator;
