import { Snackbar, Link, IconButton, useTheme } from '@mui/joy';
import {
  faCircleExclamation,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosError } from 'axios';

export type ErrorMessagePopupProps = {
  isError: boolean;
  error?: unknown;
  retry?: () => void;
  onDismiss?: () => void;
};

const ErrorMessagePopup = ({
  isError,
  error,
  retry,
  onDismiss,
}: ErrorMessagePopupProps) => {
  const joyUiTheme = useTheme();

  return (
    <Snackbar open={isError} color='danger' size='lg'>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <FontAwesomeIcon icon={faCircleExclamation} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 'bold' }}>Something went wrong</div>
          <div style={{ fontSize: '14px' }}>
            {error instanceof AxiosError
              ? (error.response?.data?.error?.message ?? error?.message)
              : 'An unknown error occurred'}
          </div>
          <div style={{ fontSize: '14px' }}>
            {retry ? (
              <Link onClick={retry} color='danger' sx={{ fontSize: '14px' }}>
                Please click here to try again
              </Link>
            ) : (
              <>Please try again</>
            )}
          </div>
          {axios.isAxiosError(error) && (
            <div style={{ fontSize: '12px', marginTop: '8px' }}>
              <div>
                Correlation ID: {error.config?.headers['correlation-id']}
              </div>
              <div>Error Code: {error.response?.data?.error?.code}</div>
            </div>
          )}
        </div>
        {onDismiss && (
          <div
            style={{
              display: 'flex',
              alignSelf: 'flex-start',
              height: '100%',
              flexGrow: 1,
            }}
          >
            <IconButton onClick={onDismiss}>
              <FontAwesomeIcon icon={faXmark} color={joyUiTheme.colorSchemes.light.palette.danger[500]} />
            </IconButton>
          </div>
        )}
      </div>
    </Snackbar>
  );
};

export default ErrorMessagePopup;
