import React from 'react';
import Card from '../card/card';
import { css } from 'aphrodite';
import projectCardStyles from './project-card.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleArrowRight,
  faKey,
  faFileLines,
  faComments,
} from '@fortawesome/free-solid-svg-icons';
import {
  PermittedUserActions,
  ProjectListResponse,
  ReleaseStatus as ReleaseStatusType,
} from '../../types/project';
import Subtitle from '../subtitle/subtitle';
import ReleaseStatus from '../release-status/release-status';
import generalUtil from '../../util/general';
import FlagTrain from '../flag-train/flag-train';
import { Button, Typography, useTheme } from '@mui/joy';
import { Link, useNavigate } from 'react-router-dom';
import { Page } from '../../types/navigation';

export type ProjectCardProps = {
  project: ProjectListResponse;
};

const ProjectCard = ({ project }: ProjectCardProps) => {
  const displayReleaseDate = React.useMemo(
    () =>
      generalUtil.formatData(
        project.latestVersion?.releaseDate,
        generalUtil.formatDate,
      ),
    [project],
  );

  const releaseOverdue = React.useMemo(() => {
    if (
      !project.latestVersion ||
      project.latestVersion?.status.code === ReleaseStatusType.Released
    ) {
      return false;
    }

    const releaseDate = new Date(project.latestVersion?.releaseDate);
    return releaseDate < new Date();
  }, [project]);

  const joyUiTheme = useTheme();
  const navigate = useNavigate();

  return (
    <Card sx={{ padding: 0, width: '360px', height: '380px' }}>
      <>
        <div
          className={css(projectCardStyles.heading)}
          data-testid={`project-card-heading-${project.id}`}
        >
          <Link
            to={`${Page.ProjectRoute}/${project.id}`}
            style={{ textDecoration: 'none' }}
          >
            <div className={css(projectCardStyles.headingText)}>
              Details
              <FontAwesomeIcon icon={faCircleArrowRight} />
            </div>
          </Link>
        </div>
        <div className={css(projectCardStyles.contentContainer)}>
          <div>
            <Typography level='h2' fontSize={32} noWrap>
              {project.name}
            </Typography>
            <div>{project.latestVersion?.versionName}</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '8px'
            }}
          >
            <div style={{ width: '65%' }}>
              <Subtitle>Current Status</Subtitle>
              <ReleaseStatus status={project.latestVersion?.status} shortLabel />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'right',
              }}
            >
              <Subtitle>Release Date</Subtitle>
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  color: releaseOverdue
                    ? joyUiTheme.colorSchemes.light.palette.danger[500]
                    : 'black',
                }}
              >
                <div>{displayReleaseDate}</div>
              </div>
            </div>
          </div>
          <div data-testid={`project-card-languages-container-${project.id}`}>
            <Subtitle>Languages</Subtitle>
            <FlagTrain langs={[project.defaultLang, ...project.langs]} />
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            {project.latestVersion &&
              (project.userActions.includes(
                PermittedUserActions.AddAndReviewKeys,
              ) ||
                project.userActions.includes(
                  PermittedUserActions.ReviewKeys,
                )) && (
                <Button
                  startDecorator={<FontAwesomeIcon icon={faKey} />}
                  onClick={() =>
                    navigate(
                      `${Page.ProjectRoute}/${project.id}/${project.latestVersion?.versionId}/${Page.Keys}`,
                    )
                  }
                >
                  Label Keys
                </Button>
              )}
            {project.latestVersion &&
              project.userActions.includes(PermittedUserActions.Translate) && (
                <Button
                  startDecorator={<FontAwesomeIcon icon={faComments} />}
                  onClick={() =>
                    navigate(
                      `${Page.ProjectRoute}/${project.id}/${project.latestVersion?.versionId}/${Page.Translate}`,
                    )
                  }
                >
                  Translate
                </Button>
              )}
            {project.latestVersion &&
              project.userActions.includes(
                PermittedUserActions.ReviewTranslations,
              ) && (
                <Button
                  startDecorator={<FontAwesomeIcon icon={faFileLines} />}
                  onClick={() =>
                    navigate(
                      `${Page.ProjectRoute}/${project.id}/${project.latestVersion?.versionId}/${Page.ReviewTranslations}`,
                    )
                  }
                  color={
                    project.userActions.includes(PermittedUserActions.Translate)
                      ? 'neutral'
                      : 'primary'
                  }
                >
                  Translations
                </Button>
              )}
          </div>
        </div>
      </>
    </Card>
  );
};

export default ProjectCard;
