import { StyleSheet } from "aphrodite";

const keysTableStyles = StyleSheet.create({
    heading: {
        height: 'fit-content', 
        paddingTop: 0,
        textAlign: 'center',
        overflow: 'visible'
    }
});

export default keysTableStyles;