import { StyleSheet } from "aphrodite";

const translationsTableStyles = StyleSheet.create({
    heading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
    }
});

export default translationsTableStyles;