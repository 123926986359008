import {
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy';
import React from 'react';

export type KeyFormatModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CodeStyleWrapper = ({ children }: { children: string }) => {
  return (
    <Typography
      variant='soft'
      fontFamily={'monospace'}
      sx={{ fontSize: '14px' }}
    >
      {children}
    </Typography>
  );
};

const KeyFormatModal = ({ isOpen, onClose }: KeyFormatModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalDialog>
        <DialogTitle>Valid Key Format</DialogTitle>
        <ModalClose />
        <DialogContent>
          <div>A translation key must:</div>
          <ul>
            <li>Not exceed 255 characters in length</li>
            <li>
              <Typography>
                Use only letters, numbers,{' '}
                <CodeStyleWrapper>_</CodeStyleWrapper>, and{' '}
                <CodeStyleWrapper>-</CodeStyleWrapper>
              </Typography>
            </li>
            <li>
              <Typography>
                Separate segments with <CodeStyleWrapper>.</CodeStyleWrapper>
                {'(e.g. '}
                <CodeStyleWrapper>abc.def</CodeStyleWrapper>
                {')'}
              </Typography>
            </li>
            <li>
              <Typography>
                Not contain double dots{' ('}
                <CodeStyleWrapper>..</CodeStyleWrapper>
                {')'}
              </Typography>
            </li>
            <li>
              <Typography>
                Not contain any leading or trailing dots{' ('}
                <CodeStyleWrapper>.</CodeStyleWrapper>
                {')'}
              </Typography>
            </li>
            <li>Not contain spaces</li>
            <li>
              <Typography>
                Not contain segments that start with a number{' (e.g. '}
                <CodeStyleWrapper>abc.1de</CodeStyleWrapper>
                {')'}
              </Typography>
            </li>
            <li>
              <Typography>
                Not be a parent or child of another key{' (e.g. '}
                <CodeStyleWrapper>abc.def</CodeStyleWrapper>
                is the parent of{' '}
                <CodeStyleWrapper>abc.def.ghi</CodeStyleWrapper>
                {')'}
              </Typography>
            </li>
          </ul>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default KeyFormatModal;
