import { StyleSheet } from 'aphrodite';
import theme from '../../styles/theme';

const profileNameStyles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nameAndProfileGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '42px',
    minWidth: '42px'
  },
  iconContainerSmall: {
    display: 'flex',
    alignItems: 'center',
    width: '36px',
    minWidth: '36px'
  },
  tagline: {
    fontSize: '14px',
  },
  timeline: {
    fontSize: '14px',
    color: theme.colour.grey,
  },
});

export default profileNameStyles;
