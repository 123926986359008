import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Page from './components/page/page';
import Layout from './components/layout/layout';
import Dashboard from './pages/dashboard/dashboard';
import CreateProject from './pages/create-project/create-project';
import NotFound from './pages/404/not-found';
import ProjectDetails from './pages/project-details/project-details';
import RouterScroll from './util/router-scroll';
import KeysScreen from './pages/keys/keys';
import TranslateScreen from './pages/translate/translate';
import ReleaseTranslations from './pages/release-translations/release-translations';

const App = () => {
  return (
    <BrowserRouter>
      <RouterScroll />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Page component={<Dashboard />} pageTitle='Dashboard' />} />
          <Route path='project'>
            <Route path='create' element={<Page component={<CreateProject />} pageTitle='Create Project' />} />
            <Route path=':projectId' element={<Page component={<ProjectDetails />} pageTitle='Project Details' />} />
            <Route path=':projectId/:releaseId/keys' element={<Page component={<KeysScreen />} pageTitle='Add Translation Keys' />} />
            <Route path=':projectId/:releaseId/translate' element={<Page component={<TranslateScreen />} pageTitle='Translate: Select a Language' />} />
            <Route path=':projectId/:releaseId/translate/:lang' element={<Page component={<TranslateScreen />} pageTitle='Translate' />} />
            <Route path=':projectId/:releaseId/translations' element={<Page component={<ReleaseTranslations />} pageTitle='Translations' />} />
            <Route index path='*' element={<Page component={<NotFound />} pageTitle='Not Found' />} />
          </Route>
          <Route path='*' element={<Page component={<NotFound />} pageTitle='Not Found' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
