import {
  faCheckCircle,
  faCircleMinus,
  faXmarkCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, FormLabel, useTheme } from '@mui/joy';
import React from 'react';

export type FormLabelWithIconProps = {
  children: React.ReactNode;
  completed?: boolean;
  errored?: boolean;
  required?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
};

type FormLabelIcon = {
  icon: IconDefinition;
  colour: 'success' | 'danger' | 'neutral';
};

const getIcon = (completed?: boolean, errored?: boolean): FormLabelIcon => {
  if (errored) {
    return {
      icon: faXmarkCircle,
      colour: 'danger',
    };
  } else if (completed) {
    return {
      icon: faCheckCircle,
      colour: 'success',
    };
  } else {
    return {
      icon: faCircleMinus,
      colour: 'neutral',
    };
  }
};

const FormLabelWithIcon = ({
  children,
  completed,
  errored,
  required,
  loading,
  style,
}: FormLabelWithIconProps) => {
  const joyUiTheme = useTheme();

  const icon = React.useMemo(
    () => getIcon(completed, errored),
    [completed, errored],
  );

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        marginBottom: '4px',
      }}
    >
      <FormLabel required={required} sx={{ margin: 0, '--FormLabel-asteriskColor': joyUiTheme.vars.palette.danger[500] }}>
        {children}
      </FormLabel>
      {loading ? (
        <CircularProgress size='sm' variant='plain' sx={{ '--CircularProgress-size': '16px' }} />
      ) : (
        <FontAwesomeIcon
          icon={icon.icon}
          color={joyUiTheme.colorSchemes.light.palette[icon.colour][500]}
          size='xs'
        />
      )}
    </div>
  );
};

export default FormLabelWithIcon;
