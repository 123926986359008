import { AxiosResponse } from "axios";
import { formatDistanceToNow, parseISO } from "date-fns";

const generalUtil = {
    /**
     * 
     * @param isoDateString  - an ISO date string
     * @returns - a date in the format e.g. Mon 11 Nov 2024
     */
    formatDate: (isoDateString: string): string => {
        const formatOptions: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        };

        return new Intl.DateTimeFormat('en-GB', formatOptions).format(new Date(isoDateString)).replace(/,/g, '');
    },
    /**
     * 
     * @param data - the data to format, which is potentially undefined
     * @param transform - a function to transform the data if it is defined
     * @returns - the data (optionally transformed), or a placeholder
     */
    formatData: (data?: string, transform?: (data: string) => string): string => {
        return data ? transform ? transform(data) : data : '--';
    },
        /**
     * 
     * @param dateString date string that we want to check validity of
     * @returns `true` if `dateString` is a valid ISO date string, or `false` if it is not
     */
        isValidISODate: (dateString: string) => {
            try {
                const date = new Date(dateString);
                return date.toISOString() === dateString;
            } catch (err) {
                // if errors, likely an invalid date string
                return false;
            } 
        },
        /**
         * @returns `true` if date1 is greater than (after) date2, `false` if date1 is less than (before) date2, or `undefined` if they are equal
         */
        compareDates: (date1: string, date2: string) => {
            if (date1 === date2) {
                return undefined;
            }
    
            return new Date(date1) > new Date(date2);
        },
        /**
         * Gets a ISO date string with time set to midnight, either from a provided date or from current date (if no provided date)
         * 
         * @param date - the date to convert
         * @returns an ISO date string set to midnight UTC, either of `date` or current date
         */
        getDateWithoutTime: (date?: string) => {
            const toConvert = date ?? new Date().toISOString();

            return `${toConvert.split('T')[0]}T00:00:00.000Z`
        },
        /**
         * Used to extract a filename from a file download, uisng the 'content-disposition' header
         * 
         * @param axiosResponse a full AxiosResponse object, including headers
         * @returns the filename of the file from the 'content-disposition' header
         */
        getFileNameFromDownload: (axiosResponse: AxiosResponse) => {
            const contentDispositionHeader = axiosResponse.headers['content-disposition'];

            if (contentDispositionHeader) {
                const segments = contentDispositionHeader.split('"');
                if (segments[1] && typeof segments[1] === 'string' && segments[1].startsWith('OneTranslate')) {
                    return segments[1];
                }
            }

            return 'OneTranslate_TranslationJSON.zip';
        },
        getTimestampRelativeToNow: (isoTimestamp: string): string => {
            return formatDistanceToNow(parseISO(isoTimestamp), { addSuffix: true });
        }
};

export default generalUtil;