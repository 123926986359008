import { User as Auth0User } from "@auth0/auth0-react"
import { User } from "../types/user"

const authUtil = {
    getCurrentUserObject: (auth0User: Auth0User): User => {
        const { sub, email, picture, name, given_name, family_name } = auth0User;

        return {
            id: sub as string,
            email: email as string,
            name: {
                full: name ?? email as string,
                forename: given_name ?? name ?? email as string,
                surname: family_name
            },
            picture
        }
    },
    ROLE_ID_TOKEN_CLAIM_KEY: 'https://auth.onetranslate.co.uk/roles'
};

export default authUtil;