import { CircularProgress, useTheme } from '@mui/joy';
import React from 'react';

export type CircularProgressPercentProps = {
  value: number;
  opposingColour?: boolean;
  "data-testid"?: string;
};

const determineColour = (value: number, opposingColour: boolean) => {
  if (value === 0) {
    return opposingColour ? 'success' : 'danger';
  }

  if (value === 100) {
    return opposingColour ? 'danger' : 'success';
  }

  return 'warning';
};

const CircularProgressPercent = ({
  value,
  opposingColour = false,
  "data-testid": datatestid,
}: CircularProgressPercentProps) => {
  const joyUiTheme = useTheme();

  const displayValue = React.useMemo(() => Math.round(value), [value]);

  const colour = React.useMemo(
    () => determineColour(value, opposingColour),
    [value, opposingColour],
  );

  return (
    <CircularProgress
      determinate
      value={value}
      color={colour}
      variant='solid'
      thickness={4}
      data-testid={datatestid}
      sx={{ '--CircularProgress-size': '36px' }}
    >
      <span
        style={{
          fontSize: '12px',
          color: joyUiTheme.colorSchemes.light.palette[colour][500],
        }}
      >
        {displayValue}
      </span>
    </CircularProgress>
  );
};

export default CircularProgressPercent;
