import React, { useState } from 'react';
import Card from '../card/card';
import {
  Button,
  FormHelperText,
  Link,
  Skeleton,
  Textarea,
  useTheme,
} from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faCircleExclamation,
  faCircleQuestion,
  faFloppyDisk,
  faRobot,
} from '@fortawesome/free-solid-svg-icons';
import { AutoTranslationInput, Language, LanguageData } from '../../types/misc';
import Subtitle from '../subtitle/subtitle';
import CountryFlag from '../country-flag/country-flag';
import theme from '../../styles/theme';
import { TranslationData } from '../../types/translation';
import {
  TranslationFieldData,
  TranslationFormValues,
} from '../../pages/translate/translate.helper';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';
import useAuth from '../../util/auth-hook';
import miscService from '../../service/misc';
import ErrorMessagePopup from '../error-message/error-message-popup/error-message-popup';

export type TranslateComponentProps = {
  lang?: LanguageData;
  defaultLang?: Language;
  form: UseFormReturn<TranslationFormValues, any, any>;
  isLoading?: boolean;
  disabled?: boolean;
  existingTranslations?: TranslationData[];
  card?: boolean;
  showCount?: boolean;
  onSave?: () => void;
  isSaving?: boolean;
};

const mapExistingTranslationsToFieldData = (
  existingTranslations: TranslationData[],
): TranslationFieldData[] => {
  return existingTranslations.map((trnsl) => ({
    key: trnsl.key,
    primaryValue: trnsl.primaryValue,
    context: trnsl.context,
    translatedValue: trnsl.translatedValue,
    autoTranslatedValue: trnsl.autoTranslated
      ? trnsl.translatedValue
      : undefined,
    placeholder: trnsl.placeholder,
  }));
};

const TranslateComponent = ({
  lang,
  defaultLang,
  form,
  isLoading = false,
  disabled,
  existingTranslations,
  card = true,
  showCount = true,
  onSave,
  isSaving,
}: TranslateComponentProps) => {
  const joyUiTheme = useTheme();
  const { getAccessTokenSilently } = useAuth();

  const [pageNumber, setPageNumber] = useState(0);

  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'translation',
  });

  React.useEffect(() => {
    if (existingTranslations) {
      // remove all fields and add returned data
      remove();
      append(mapExistingTranslationsToFieldData(existingTranslations));
    }
  }, [existingTranslations, remove, append]);

  const errorPageNum = (() => {
    let errorIndex = undefined;
    for (let i = 0; i < fields.length; i++) {
      if (errors.translation?.[i]?.translatedValue) {
        errorIndex = i;
        break;
      }
    }

    return errorIndex;
  })();

  const {
    isLoading: isAutoTranslationLoading,
    isError: isAutoTranslationError,
    error: autoTranslationError,
    mutate: autoTranslate,
    reset: resetAutoTranslate,
  } = useMutation(
    async (input: AutoTranslationInput) => {
      const accessToken = await getAccessTokenSilently();

      return await miscService.translateText(accessToken, input);
    },
    {
      onSuccess: (response) => {
        setValue(
          `translation.${pageNumber}.translatedValue`,
          response.translatedText,
        );
        setValue(
          `translation.${pageNumber}.autoTranslatedValue`,
          response.translatedText,
        );
      },
    },
  );

  const handleNext = () => {
    setPageNumber((num) => num + 1);
  };

  const handlePrev = () => {
    setPageNumber((num) => num - 1);
  };

  const handleSave = () => {
    resetAutoTranslate();
    if (onSave) {
      onSave();
    }
  }

  const handleAutoTranslate = () => {
    autoTranslate({
      text: fields[pageNumber].primaryValue,
      sourceLang: defaultLang as string,
      targetLang: lang?.code as string,
    });
  };

  return (
    <>
      <ErrorMessagePopup
        isError={isAutoTranslationError}
        error={autoTranslationError}
        onDismiss={resetAutoTranslate}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Card sx={{ boxShadow: card ? 'sm' : 'none' }}>
          <div style={{ display: 'flex', gap: '32px' }}>
            <div
              style={{
                width: '75%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Skeleton variant='rectangular' loading={isLoading}>
                <div style={{ fontSize: '24px' }}>
                  {fields[pageNumber]?.primaryValue ?? 'Initialising...'}
                </div>
              </Skeleton>
              <Skeleton variant='rectangular' loading={isLoading}>
                {(fields[pageNumber]?.context || isLoading) && (
                  <div style={{ color: theme.colour.grey, fontSize: '16px' }}>
                    <Subtitle style={{ margin: 0 }}>Context</Subtitle>
                    <div>
                      {fields[pageNumber]?.context ?? 'Initialising...'}
                    </div>
                  </div>
                )}
              </Skeleton>
              <div>
                <Skeleton variant='rectangular' loading={isLoading}>
                  <Textarea
                    minRows={4}
                    maxRows={4}
                    error={
                      !!errors.translation?.[pageNumber]?.translatedValue
                        ?.message
                    }
                    disabled={disabled || isAutoTranslationLoading}
                    key={fields[pageNumber]?.id}
                    data-testid='translate-input'
                    {...register(`translation.${pageNumber}.translatedValue`)}
                  />
                </Skeleton>
                {!!errors.translation?.[pageNumber]?.translatedValue && (
                  <FormHelperText
                    sx={{ color: joyUiTheme.vars.palette.danger[500] }}
                  >
                    {errors.translation?.[pageNumber]?.translatedValue?.message}
                  </FormHelperText>
                )}
              </div>
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Subtitle>Translating Into</Subtitle>
                <Skeleton variant='inline' loading={isLoading || !lang}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                      fontSize: '18px',
                    }}
                  >
                    {lang ? (
                      <CountryFlag languageCode={lang.code} size='24px' />
                    ) : (
                      <span style={{ fontSize: '24px' }}>
                        <FontAwesomeIcon icon={faCircleQuestion} />
                      </span>
                    )}
                    <div>{lang?.name ?? 'Unknown'}</div>
                  </div>
                </Skeleton>
              </div>
              {fields[pageNumber]?.placeholder && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <div
                    style={{
                      color: joyUiTheme.colorSchemes.light.palette.warning[400],
                      display: 'flex',
                      alignItems: 'center',
                      gap: '16px',
                    }}
                  >
                    <div style={{ fontSize: '42px' }}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                    </div>
                    <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                      Contains Placeholder
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <div>
                      This label contains either a placeholder, or embedded
                      HTML.
                    </div>
                    <div>{`Please ensure that any letters or words between angled or curly brackets (< and >, or { and } ) remain unchanged`}</div>
                  </div>
                </div>
              )}
              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent:
                    errorPageNum !== undefined ? 'space-between' : 'flex-end',
                  fontSize: '18px',
                  fontWeight: '600',
                }}
              >
                {errorPageNum !== undefined && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      color={joyUiTheme.colorSchemes.light.palette.danger[500]}
                    />
                    <Link
                      sx={{ fontSize: '14px', fontWeight: 'normal' }}
                      color='danger'
                      component='button'
                      onClick={() => {
                        if (errorPageNum !== undefined) {
                          setPageNumber(errorPageNum);
                        }
                      }}
                    >
                      Error on translation {errorPageNum + 1}
                    </Link>
                  </div>
                )}
                {showCount && (
                  <Skeleton variant='rectangular' loading={isLoading}>
                    <div>
                      {pageNumber + 1} of {fields.length}
                    </div>
                  </Skeleton>
                )}
              </div>
            </div>
          </div>
        </Card>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            startDecorator={<FontAwesomeIcon icon={faRobot} />}
            onClick={handleAutoTranslate}
            disabled={!defaultLang || !lang}
            loading={isAutoTranslationLoading}
          >
            Auto Translate
          </Button>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '8px',
            }}
          >
            {existingTranslations && existingTranslations.length > 1 && (
              <>
                <Button
                  startDecorator={<FontAwesomeIcon icon={faAngleLeft} />}
                  disabled={
                    pageNumber <= 0 || disabled || isAutoTranslationLoading
                  }
                  onClick={handlePrev}
                >
                  Previous
                </Button>
                <Button
                  endDecorator={<FontAwesomeIcon icon={faAngleRight} />}
                  disabled={
                    pageNumber >= fields.length - 1 ||
                    disabled ||
                    isAutoTranslationLoading
                  }
                  onClick={handleNext}
                >
                  Next
                </Button>
              </>
            )}
            {onSave && (
              <Button
                startDecorator={<FontAwesomeIcon icon={faFloppyDisk} />}
                onClick={handleSave}
                loading={isSaving}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslateComponent;
