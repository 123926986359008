import { StyleSheet } from "aphrodite";

const languageTranslationStatsStyles = StyleSheet.create({
    colContainer: {
        textAlign: 'center',
        verticalAlign: 'center'
    },
    topGradient: {
        '::before': {
            content: '""',
            position: 'absolute',
            height: '40px',
            width: 'calc(100% - 32px)',
            background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);',
            zIndex: 5,
        },
      },
      bottomGradient: {
        '::after': {
            content: '""',
            position: 'absolute',
            bottom: '16px',
            height: '40px',
            width: 'calc(100% - 32px)',
            background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);',
            zIndex: 5,
        }
      },
});

export default languageTranslationStatsStyles;