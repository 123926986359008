import React from 'react';
import {
  Language,
  LanguageData,
  supportedLanguages,
} from '../../../types/misc';
import {
  DialogContent,
  DialogTitle,
  FormControl,
  Modal,
  ModalDialog,
  Select,
  Option,
  Skeleton,
  Button,
  Link,
  IconButton,
  ModalClose,
} from '@mui/joy';
import { Controller, useForm } from 'react-hook-form';
import CountryFlag from '../../../components/country-flag/country-flag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRobot, faXmark } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../styles/theme';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Page } from '../../../types/navigation';
import ErrorMessage from '../../../components/error-message/error-message';
import Message from '../../../components/message/message';
import useAuth from '../../../util/auth-hook';
import { useMutation } from 'react-query';
import translationService from '../../../service/translation';
import { AxiosError } from 'axios';

export type LanguageSelectionModalProps = {
  isOpen: boolean;
  projectId: number;
  releaseId: number;
  untranslatedLangs?: LanguageData[];
  isLoading: boolean;
  isError: boolean;
  untranslatedLangsError: unknown;
  refetchUntranslatedLangs: () => void;
};

type FormValues = {
  lang: Language;
};

const LanguageSelectionModal = ({
  isOpen,
  projectId,
  releaseId,
  untranslatedLangs,
  isLoading,
  isError,
  untranslatedLangsError,
  refetchUntranslatedLangs,
}: LanguageSelectionModalProps) => {
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth();

  const [
    showBulkAutoTranslateConfirmation,
    setShowBulkAutoTranslateConfirmation,
  ] = React.useState(false);

  const validationSchema = Yup.object().shape({
    lang: Yup.mixed<Language>().oneOf(supportedLanguages).required(),
  });

  const {
    control,
    watch,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const selectedLang = watch('lang');

  const {
    isLoading: isBulkAutoTranslateLoading,
    isError: isBulkAutoTranslateError,
    error: bulkAutoTranslateError,
    mutate: submitBulkAutoTranslate,
    reset: resetBulkAutoTranslate,
  } = useMutation(
    async () => {
      const accessToken = await getAccessTokenSilently();

      return await translationService.autoTranslateLangInRelease(
        accessToken,
        Number(releaseId),
        selectedLang,
      );
    },
    {
      onSuccess: (response) => {
        navigate({
          pathname: `${Page.ProjectRoute}/${projectId}/${releaseId}/${Page.ReviewTranslations}`,
          search: createSearchParams(
            response.hasStatusChanged
              ? { statusChanged: 'true', lang: selectedLang }
              : { lang: selectedLang },
          ).toString(),
        });
      },
    },
  );

  const handleSubmit = () => {
    resetBulkAutoTranslate();
    navigate(
      `${Page.ProjectRoute}/${projectId}/${releaseId}/${Page.Translate}/${selectedLang}`,
    );
  };

  const handleClose = () => {
    resetBulkAutoTranslate();
    navigate(`${Page.ProjectRoute}/${projectId}`);
  };

  const handleAutoTranslateCancel = () => {
    setShowBulkAutoTranslateConfirmation(false);
    resetBulkAutoTranslate();
  };

  const selectedLangName = React.useMemo(
    () =>
      untranslatedLangs?.find((data) => data.code === selectedLang)?.name ??
      'Unknown',
    [selectedLang, untranslatedLangs],
  );

  const failedKeys = React.useMemo(() => {
    const apiError =
      bulkAutoTranslateError instanceof AxiosError
        ? bulkAutoTranslateError.response?.data?.error
        : undefined;

    if (
      apiError?.code === 'ERR_TRNS_BAUTO_FAIL' &&
      apiError?.detail?.failedKeys &&
      Array.isArray(apiError?.detail?.failedKeys) &&
      apiError?.detail?.failedKeys.length > 0
    ) {
      return apiError?.detail?.failedKeys as string[];
    }
  }, [bulkAutoTranslateError]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalDialog maxWidth={'400px'}>
        <DialogTitle>Select a Language</DialogTitle>
        <ModalClose />
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {(untranslatedLangs && untranslatedLangs.length > 0) ||
            isError ||
            isLoading ? (
              <>
                <div>Please select a language to translate labels into</div>
                <div>
                  <Skeleton
                    variant='rectangular'
                    loading={isLoading || isError}
                  >
                    <FormControl>
                      <Controller
                        name='lang'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            placeholder='Select a language...'
                            startDecorator={
                              value ? (
                                <CountryFlag languageCode={value} />
                              ) : undefined
                            }
                            value={value}
                            onChange={(event, newValue) => {
                              onChange(newValue);
                            }}
                            disabled={showBulkAutoTranslateConfirmation}
                          >
                            {untranslatedLangs?.map((supportedLang) => (
                              <Option
                                value={supportedLang.code}
                                key={supportedLang.code}
                              >
                                <CountryFlag
                                  languageCode={supportedLang.code}
                                />
                                {supportedLang.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Skeleton>
                </div>
                <div style={{ fontSize: '14px', color: theme.colour.grey }}>
                  Note: only languages that have not been fully translated are
                  available for selection
                </div>
              </>
            ) : (
              <Message variant='success' title='Translations Complete'>
                <div>
                  All translations have been completed!{' '}
                  <Link
                    component='a'
                    href={`${Page.ProjectRoute}/${projectId}/${releaseId}/${Page.ReviewTranslations}`}
                    color='success'
                  >
                    Click here to review them
                  </Link>
                </div>
              </Message>
            )}
            <ErrorMessage
              isError={isError}
              error={untranslatedLangsError}
              retry={refetchUntranslatedLangs}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '8px',
              }}
            >
              <Button
                startDecorator={<FontAwesomeIcon icon={faRobot} />}
                disabled={!isValid || showBulkAutoTranslateConfirmation}
                onClick={() => setShowBulkAutoTranslateConfirmation(true)}
              >
                Auto Translate
              </Button>
              {!showBulkAutoTranslateConfirmation && (
                <Button
                  startDecorator={<FontAwesomeIcon icon={faCheck} />}
                  disabled={!isValid}
                  onClick={handleSubmit}
                >
                  Confirm
                </Button>
              )}
            </div>
            {showBulkAutoTranslateConfirmation && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '16px',
                    margin: '8px 0',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                    }}
                  >
                    <div>
                      Are you sure you want to Auto Translate all{' '}
                      <b>{selectedLangName}</b> translations?
                    </div>
                    <div style={{ fontSize: '14px' }}>
                      Any translations already translated will not be replaced
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <IconButton
                        color='success'
                        variant='solid'
                        onClick={() => submitBulkAutoTranslate()}
                        loading={isBulkAutoTranslateLoading}
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton
                        color='danger'
                        variant='solid'
                        onClick={handleAutoTranslateCancel}
                        disabled={isBulkAutoTranslateLoading}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </IconButton>
                    </div>
                  </div>
                </div>
                <ErrorMessage
                  isError={isBulkAutoTranslateError}
                  error={bulkAutoTranslateError}
                  retry={submitBulkAutoTranslate}
                >
                  {failedKeys && (
                    <>
                      <div>
                        The following keys were unable to be auto translated
                      </div>
                      <ul>
                        {failedKeys.map((failedKey) => (
                          <li key={failedKey}>{failedKey}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </ErrorMessage>
              </div>
            )}
          </div>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default LanguageSelectionModal;
