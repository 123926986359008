import { GitHubRepoDetails, GitRepoValidationInput, JSONFileDownloadData } from "../types/git";
import { ProjectRelease } from "../types/project";
import axiosClient from "../util/axios";
import { getEnvVariables } from "../util/env-variables";
import generalUtil from "../util/general";

const gitService = {
    validateRepo: async (accessToken: string, input: GitRepoValidationInput): Promise<GitHubRepoDetails> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.post(`${apiRootUrl}/git/validate`, input, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as GitHubRepoDetails;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    release: async (accessToken: string, releaseId: number): Promise<ProjectRelease> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.post(`${apiRootUrl}/git/${releaseId}/release`, undefined, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as ProjectRelease;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    downloadReleaseFiles: async (accessToken: string, releaseId: number): Promise<JSONFileDownloadData> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/git/${releaseId}/download`, { responseType: 'blob', headers: { Authorization: `Bearer ${accessToken}` } });

            return {
                file: response.data,
                fileName: generalUtil.getFileNameFromDownload(response)
            };
        } catch (err) {
            return Promise.reject(err as Error);
        }
    }
};

export default gitService;