import { css } from 'aphrodite';
import React from 'react';
import animatedCheckCirclesStyles from './animated-check-circle.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/joy';

const AnimatedCheckCircle = () => {
  const joyUiTheme = useTheme();

  return (
    <div className={css(animatedCheckCirclesStyles.outerContainer)}>
      <div className={css(animatedCheckCirclesStyles.innerContainer)}>
        <FontAwesomeIcon
          style={{ position: 'absolute', zIndex: 5 }}
          icon={faCheckCircle}
          color={joyUiTheme.colorSchemes.light.palette.success[500]}
          size='4x'
        />
        <div className={css(animatedCheckCirclesStyles.whiteUnderlay)} />
        <div
          className={css(
            animatedCheckCirclesStyles.base,
            animatedCheckCirclesStyles.sizeAnimation,
            animatedCheckCirclesStyles.opacityAnimation
          )}
        />
      </div>
    </div>
  );
};

export default AnimatedCheckCircle;
