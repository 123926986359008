import { BasicReleaseDetails, CreateProjectReleaseInput, Project, ProjectCreateInput, ProjectDetails, ProjectListResponse, ProjectRelease } from "../types/project";
import { getEnvVariables } from "../util/env-variables";
import axiosClient from "../util/axios";

const projectService = {
    createProject: async (accessToken: string, project: ProjectCreateInput): Promise<Project> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.post(`${apiRootUrl}/project`, project, { headers: { Authorization: `Bearer ${accessToken}`}});
    
            return response.data.data as Project;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    getAllProjects: async (accessToken: string): Promise<ProjectListResponse[]> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/project`, { headers: { Authorization: `Bearer ${accessToken}`}});

            return response.data.data as ProjectListResponse[];
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    getProjectDetails: async (accessToken: string, projectId: number, includeVersions?: boolean, includeUsers?: boolean): Promise<ProjectDetails> => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/project/${projectId}`, { headers: { Authorization: `Bearer ${accessToken}` }, params: { includeUsers, includeVersions } });

            return response.data.data as ProjectDetails;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    createRelease: async (accessToken: string, projectId: number, input: CreateProjectReleaseInput) => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.post(`${apiRootUrl}/project/${projectId}/release`, input, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as ProjectRelease;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    getBasicReleaseDetails: async (accessToken: string, releaseId: number, projectId: number) => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.get(`${apiRootUrl}/project/${projectId}/${releaseId}`, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as BasicReleaseDetails;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    },
    approveRelease: async (accessToken: string, releaseId: number, projectId: number) => {
        try {
            const { apiRootUrl } = getEnvVariables();

            const response = await axiosClient.patch(`${apiRootUrl}/project/${projectId}/${releaseId}/approve`, undefined, { headers: { Authorization: `Bearer ${accessToken}` } });

            return response.data.data as ProjectRelease;
        } catch (err) {
            return Promise.reject(err as Error);
        }
    }
};

export default projectService;