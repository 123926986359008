import {
  faAngleRight,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  useTheme,
} from '@mui/joy';
import React from 'react';
import ErrorMessage from '../error-message/error-message';
import { ProjectReleaseStatusData, ReleaseStatus } from '../../types/project';
import { getNextReleaseStatusData } from './approve-release-confirmation-modal.helper';
import AnimatedCheckCircle from '../animated-check-circle/animated-check-circle';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import Message from '../message/message';

export type ApproveReleaseConfirmationModalProps = {
  isOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  handleConfirmationClose?: () => void;
  releaseName?: string;
  status?: ProjectReleaseStatusData;
  isLoading: boolean;
  isError: boolean;
  error?: unknown;
  showConfirmation?: boolean;
  gitUrl?: string;
  allReviewed?: boolean;
};

const ApproveReleaseConfirmationModal = ({
  isOpen,
  handleCancel,
  handleConfirm,
  handleConfirmationClose,
  status,
  releaseName,
  isLoading,
  isError,
  error,
  showConfirmation,
  gitUrl,
  allReviewed,
}: ApproveReleaseConfirmationModalProps) => {
  const joyUiTheme = useTheme();

  const nextReleaseStatus = React.useMemo(
    () => getNextReleaseStatusData(status?.code),
    [status],
  );

  const handleGithubNav = () => {
    if (gitUrl) {
      window.open(gitUrl, '_blank');
    }
  };

  return (
    <Modal open={isOpen}>
      <ModalDialog minWidth={'500px'} maxWidth={'500px'}>
        <DialogTitle level='h3'>Approve Release</DialogTitle>
        <DialogContent>
          {showConfirmation ? (
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h3 style={{ margin: 0 }}>Success</h3>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <AnimatedCheckCircle />
              </div>
              <div style={{ textAlign: 'center' }}>
                <b>{releaseName}</b> has been successfully released!
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '16px',
                  gap: '16px',
                }}
              >
                <Button
                  startDecorator={<FontAwesomeIcon icon={faGithub} />}
                  onClick={handleGithubNav}
                  color='neutral'
                >
                  Show on GitHub
                </Button>
                <Button
                  onClick={handleConfirmationClose}
                  startDecorator={<FontAwesomeIcon icon={faAngleRight} />}
                >
                  Return to Project
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  margin: '16px 0',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                {status?.code === ReleaseStatus.ClientReviewPostTranslation ? (
                  <>
                    <div>
                      Are you sure you want to approve the release of{' '}
                      <b>{releaseName}</b>?
                    </div>
                    <div>
                      The necessary translation JSON files will be built, and
                      pushed to the Translation Submodule Git Repository
                    </div>
                  </>
                ) : (
                  <div>
                    Are you sure you want to approve release{' '}
                    <b>{releaseName}</b> to move to{' '}
                    <b>{nextReleaseStatus?.description}</b>?
                  </div>
                )}

                <div
                  style={{
                    color: joyUiTheme.colorSchemes.light.palette.danger[500],
                  }}
                >
                  This action <b>cannot</b> be undone
                </div>
                {!allReviewed &&
                  (status?.code === ReleaseStatus.ClientReviewPreTranslation ||
                    status?.code ===
                      ReleaseStatus.ClientReviewPostTranslation) && (
                    <Message
                      variant='warning'
                      title='Some translations may not be reviewed'
                    >
                      Some translations have not been marked as reviewed. Are
                      you sure you want to continue?
                    </Message>
                  )}
                <ErrorMessage
                  isError={isError}
                  error={error}
                  retry={handleConfirm}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '16px',
                }}
              >
                <Button
                  startDecorator={<FontAwesomeIcon icon={faXmark} />}
                  onClick={handleCancel}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  color='success'
                  startDecorator={<FontAwesomeIcon icon={faCheck} />}
                  onClick={handleConfirm}
                  loading={isLoading}
                >
                  Confirm
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default ApproveReleaseConfirmationModal;
